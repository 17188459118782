<!-- pageName -->
<template>
  <div class="portal_list">
    <component v-bind:is="currentFrame"></component>
  </div>
</template>

<script>
// import ErshiFrame from '../ershi/components/ershiFrame.vue'
// const GreenPcFrameList = () => import('../../greenPc/list.vue')
export default {
  components: {
    // GreenPcFrameList,
  },
  props: {},
  data() {
    return {
      pcmodel: '',
      currentFrame: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
  },
  created() {
    this.pcmodel = JSON.parse(localStorage.getItem('pcmodel'))
    // this.currentFrame = 'ErshiFrameList'
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */

</style>
